export function handleResponse(response) {
    switch (response && response.status) {
      case 200: // OK
        // Handle 200
        // console.log(response.data);
        // if(response.data.is)
        return response;
      case 401: // Unauthorized
        // Handle 401
        this.$router.push('/login');
        console.log(Error(response.message || response.statusText));
        throw new Error(response.message || response.statusText);
      case 403: // Forbidden
        // Handle 403
        console.log(Error(response.message || response.statusText));
        throw new Error("You do not have permission to perform this action.");
      case 404: // Not Found
        // Handle 404
        console.log(Error(response.message || response.statusText));
        throw new Error("The requested resource could not be found.");
      case 419: // CSRF Token Mismatch
        // Handle 403
        console.log(Error(response.message || response.statusText));
        throw new Error("Session Expired. Please refresh the page.");
      default:
        // Handle other statuses
        console.log(Error(response.message || response.statusText));
        throw new Error(response.message || response.statusText);
    }
  }