import { productService } from "../../helpers/product.service";

export const state = {
  company: JSON.parse(localStorage.getItem("company")) || {},
  warehouse: JSON.parse(localStorage.getItem("warehouse")) || {},
  warehouses: JSON.parse(localStorage.getItem("warehouses")) || {},
  warehouseMessage: "",
  isLoading: false,
  prodMessage: "",
  errors: null,
  status: null,
};

export const mutations = {
  SET_PROD_RESPONSE(state, prodResponse) {
    state.prodMessage = prodResponse.data && prodResponse.data.message || prodResponse.message;
    state.errors = prodResponse.data && prodResponse.data.errors;
    state.status = prodResponse.status;
    console.log(state.status, prodResponse);
  },
  SET_COMPANY(state, company) {
    state.company = company;
    localStorage.setItem("company", JSON.stringify(state.company));
  },
  SET_WAREHOUSE(state, warehouse) {
    state.warehouse = warehouse;
    localStorage.setItem("warehouse", JSON.stringify(state.warehouse));
  },
  SET_WAREHOUSES(state, warehouses) {
    state.warehouses = warehouses;
    localStorage.setItem("warehouses", JSON.stringify(state.warehouses));
  },
  SET_WAREHOUSE_MESSAGE(state, warehouseMessage) {
    state.warehouseMessage = warehouseMessage.message;
    console.log(state.warehouseMessage);
  },
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
};

export const actions = {
  async getAllProducts({ commit }) {
    try {
      const company = await productService.getAllProducts();
      commit("SET_COMPANY", company);
    } catch (error) {
      return error;
    }
  },

  async createProduct({ commit }, productData) {
    try {
      const prodResponse = await productService.createProduct(productData);
      commit("SET_PROD_RESPONSE", prodResponse);
    } catch (error) {
      return error;
    }
  },

  async getWarehouse({ commit }, company) {
    const warehouse = await productService.getWarehouse(company);
    commit("SET_WAREHOUSE", warehouse);
  },

  async updateCompany({ commit }, updatedCompany) {
    const company = await productService.editCompany(updatedCompany);
    commit("SET_COMPANY", company);
  },

  async createWarehouse({ commit }, warehouseData) {
    try {
      const prodResponse = await productService.createWarehouse(warehouseData);
      commit("SET_COMP_RESPONSE", prodResponse);
    } catch (error) {
      return error;
    }
  },

  async updateWarehouse({ commit }, warehouseData) {
    try {
      const prodResponse = await productService.editWarehouse(warehouseData);
      commit("SET_COMP_RESPONSE", prodResponse);
    } catch (error) {
      return error;
    }
  },

  async listWarehouses({ commit }, token) {
    const warehouses = await productService.getWarehouses(token);
    commit("SET_WAREHOUSES", warehouses);
  },
};

export const getters = {
  company: (state) => state.company,
  warehouse: (state) => state.warehouse,
  warehouses: (state) => state.warehouses,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
