import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import appConfig from "../../app.config";
import axios from "axios";

const origin = process.env.API_ORIGIN || "https://dev.zata.rw";
console.log(origin)

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  let session = JSON.parse(sessionStorage.getItem("sessionInfo"));
  // let token = session ? session.O95HL3W : null;

  axios.defaults.withCredentials = true;
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": origin,
    'Accept': 'application/json'
  };

  if (!authRequired) return next();

  if (session && session.isLoggedIn) return next();

  // if (session.isOnboarded === '0') return next({ name: "onboard" });

  // If the user is not authenticated, redirect to login
  next({name: "login"});
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    console.error("Error in beforeResolve:", error);
    return;
  }

  next();
});

router.beforeEach((routeTo, routeFrom, next) => {
  document.title = `${routeTo.meta.title} | ${appConfig.title}`;
  next();
});
export default router;
