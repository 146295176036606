import { handleResponse } from "./response.service";

const apiUrl = process.env.API_URL || "http://localhost:8000/v1";

const headers = "";

import axios from "axios";

export const companyService = {
  getCompany,
  editCompany,
  createWarehouse,
  getWarehouse,
  getWarehouses,
  editWarehouse,
};

async function getCompany(token) {
  try {
    const products = await axios.get(`${apiUrl}/company/get-profile`, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    return products.data;
  } catch (error) {
    return error;
  }
}

async function editCompany(company) {
  try {
    const response = await axios.post(
      `${apiUrl}/company/edit-company`,
      company,
      {
        withCredentials: true,
        headers,
      }
    );
    const companyData = handleResponse(response);
    return companyData.data;
  } catch (error) {
    return error;
  }
}

async function createWarehouse(warehouse) {
  const token = warehouse && warehouse._token;
  try {
    const response = await axios.post(
      `${apiUrl}/company/warehouse/addnew`,
      warehouse,
      {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": token,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}


async function editWarehouse(warehouse) {
    const id = warehouse && warehouse.xid
    const token = warehouse && warehouse._token
  try {
    const compResponse = await axios.post(`${apiUrl}/company/warehouse/update/${id}`, warehouse, {
        withCredentials: true,
        headers: {
            "X-CSRF-TOKEN": token,
          },
      }
    );
    console.log(compResponse)
    return compResponse;
  } catch (error) {
    console.log(error)
    return error;
  }
}

async function getWarehouses(token) {
  try {
    const warehouses = await axios.get(`${apiUrl}/company/warehouses`, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    return warehouses.data;
  } catch (error) {
    return error;
  }
}

async function getWarehouse(warehouse) {
  const id = (warehouse && warehouse.id) || "";
  try {
    const response = await axios.get(`${apiUrl}/company/warehouse/${id}`, {
      withCredentials: true,
      headers,
    });
    const warehouse = handleResponse(response);
    return warehouse.data;
  } catch (error) {
    return error;
  }
}
