import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueGoogleMaps from '@fawmi/vue-google-maps'

import VueFeather from 'vue-feather';

import '@/assets/scss/config/minimal/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import VueHotjar from 'vue-hotjar';
// import { VueReCaptcha } from 'vue-recaptcha-v3'




// FakeBackend authentication
import { configureFakeBackend } from './helpers/fake-backend';
configureFakeBackend();




// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDzRUqmPoNQ-QqZPaj9bnnh79MmP-Ojieo',
            language: 'en',
            libraries: "places",
            autobindAllEvents: true
        },
    })
    .use(VueHotjar, {
        id: '3730287',
        isProduction: true,
      })
    .mount('#app');

