import { companyService } from "../../helpers/company.service";

export const state = {
  company: JSON.parse(localStorage.getItem("company")) || {},
  warehouse: JSON.parse(localStorage.getItem("warehouse")) || {},
  warehouses: JSON.parse(localStorage.getItem("warehouses")) || {},
  warehouseMessage: "",
  isLoading: false,
  companyMessage: "",
  errors: null,
  status: null,
};

export const mutations = {
  SET_COMP_RESPONSE(state, compResponse) {
    state.companyMessage =
      (compResponse.data && compResponse.data.message) || compResponse.message;
    state.errors = compResponse.data && compResponse.data.errors;
    state.status = compResponse.status;
    console.log(state.status, compResponse);
  },
  SET_COMPANY(state, company) {
    state.company = company;
    localStorage.setItem("company", JSON.stringify(state.company));
  },
  SET_WAREHOUSE(state, warehouse) {
    state.warehouse = warehouse;
    localStorage.setItem("warehouse", JSON.stringify(state.warehouse));
  },
  SET_WAREHOUSES(state, warehouses) {
    state.warehouses = warehouses;
    localStorage.setItem("warehouses", JSON.stringify(state.warehouses));
  },
  SET_WAREHOUSE_MESSAGE(state, warehouseMessage) {
    state.warehouseMessage = warehouseMessage.message;
    console.log(state.warehouseMessage);
  },
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
};

export const actions = {
  async getCompany({ commit }) {
    try {
      const company = await companyService.getCompany();
      commit("SET_COMPANY", company);
    } catch (error) {
      return error;
    }
  },

  async getWarehouse({ commit }, company) {
    const warehouse = await companyService.getWarehouse(company);
    commit("SET_WAREHOUSE", warehouse);
  },

  async updateCompany({ commit }, updatedCompany) {
    const company = await companyService.editCompany(updatedCompany);
    commit("SET_COMPANY", company);
  },

  async createWarehouse({ commit }, warehouseData) {
    try {
      const compResponse = await companyService.createWarehouse(warehouseData);
      commit("SET_COMP_RESPONSE", compResponse);
    } catch (error) {
      return error;
    }
  },

  async updateWarehouse({ commit }, warehouseData) {
    try {
      const compResponse = await companyService.editWarehouse(warehouseData);
      commit("SET_COMP_RESPONSE", compResponse);
    } catch (error) {
      return error;
    }
  },

  async listWarehouses({ commit }, token) {
    const warehouses = await companyService.getWarehouses(token);
    commit("SET_WAREHOUSES", warehouses);
  },
};

export const getters = {
  company: (state) => state.company,
  warehouse: (state) => state.warehouse,
  warehouses: (state) => state.warehouses,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
