import axios from 'axios';

const apiUrl = process.env.API_URL || 'http://localhost:8000/v1';

export const commonService = {
    getPaymentModes,
    getCurrencies,
    getTaxes,
    getUnits
};

async function getPaymentModes(token) {
    try {     
        const response = await axios.get(`${apiUrl}/common/get-payment-modes`, {
            withCredentials: true,
            headers: {
                "X-CSRF-TOKEN": token,
              },
        }); 
        return response.data;
    } catch (error) {
        return error;                                   
    }
}

async function getCurrencies(token) {
    try {     
        const response = await axios.get(`${apiUrl}/common/get-currencies`, {
            withCredentials: true,
            headers: {
                "X-CSRF-TOKEN": token,
              },
        }); 
        return response.data;
    } catch (error) {
        return error;                                   
    }
}

async function getTaxes(token) {
    try {     
        const response = await axios.get(`${apiUrl}/common/get-taxes`, {
            withCredentials: true,
            headers: {
                "X-CSRF-TOKEN": token,
              },
        }); 
        return response.data;
    } catch (error) {
        return error;                                   
    }
}

async function getUnits(token) {
    try {     
        const response = await axios.get(`${apiUrl}/common/get-units`, {
            withCredentials: true,
            headers: {
                "X-CSRF-TOKEN": token,
              },
        }); 
        return response.data;
    } catch (error) {
        return error;                                   
    }
}
