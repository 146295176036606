export default [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/home/landingPage.vue"),
    meta: {
      title: "Home",
      authRequired: false
    },
  },
  {
    path: "/legal",
    name: "Legal",
    component: () => import("../views/home/legal.vue"),
    meta: {
      title: "Home",
      authRequired: false
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/auth/loginUser.vue"),
  //   meta: {
  //     title: "Login",
  //     authRequired: false
  //   },
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/auth/registerUser.vue"),
  //   meta: {
  //     title: "Register",
  //     authRequired: false
  //   },
  // },
  // {
  //   path: "/reset-password",
  //   name: "resetpassword",
  //   component: () => import("../views/auth/resetPassword.vue"),
  //   meta: {
  //     title: "Reset Password",
  //     authRequired: false
  //   },
  // },
  // {
  //   path: "/logout",
  //   name: "logout",
  //   meta: {
  //     title: "Logout",
  //     authRequired: false
  //   },
  //   component: () => import("../views/auth/logoutUser")
  // },
  // {
  //   path: "/epos",
  //   name: "epos",
  //   meta: {
  //     title: "EPOS",
  //     authRequired: false,
  //   },
  //   component: () => import("../views/dashboard/epos.vue"),
  // },
  // {
  //   path: "/onboard",
  //   name: "onboard",
  //   meta: {
  //     title: "Onboard",
  //     authRequired: false,
  //   },
  //   component: () => import("../views/auth/onboardCompany.vue"),
  // },
  
  // {
  //   path: "/pricing",
  //   name: "Pricing",
  //   component: () => import("../views/user/pricing.vue"),
  //   meta: {
  //     title: "Pricing",
  //     authRequired: false
  //   },
  // },
];