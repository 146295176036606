import { commonService } from "../../helpers/common.service";

export const state = {
  payments: null,
  currencies: null,
  taxes: null,
  units: null,
};

export const mutations = {
  SET_PAYMENTS(state, payments) {
    state.payments = payments;    
  },
  SET_CURRENCIES(state, currencies) {
    state.currencies = currencies;
  },
  SET_TAXES(state, taxes) {
    state.taxes = taxes;
  },
  SET_UNITS(state, units) {
    state.units = units;
  },
};

export const actions = {

  async getPayments({ commit }, token) {
    const payments = await commonService.getPaymentModes(token);
    commit("SET_PAYMENTS", payments);
  },

  async getCurrencies({ commit }, token) {
    const currencies = await commonService.getCurrencies(token);
    commit("SET_CURRENCIES", currencies);
  },

  async getTaxes({ commit }, token) {
    const taxes = await commonService.getTaxes(token);
    commit("SET_TAXES", taxes);
  },

  async getUnits({ commit }, token) {
    const units = await commonService.getUnits(token);
    commit("SET_UNITS", units);
  }
}; 

export const getters = {
  payments: (state) => state.payments,
  currencies: (state) => state.currencies,
  taxes: (state) => state.taxes,
  units: (state) => state.units
};

export default {
  state,
  mutations,
  actions,
  getters,
};
