import { userService } from "../../helpers/user.service.js";

export const state = {
  sessionInfo: JSON.parse(sessionStorage.getItem("sessionInfo")) || null,
  userProfile: null,
  currentUser: null,
  authMessage: "",
  status: null,
  errors: null,
  isLoading: false,
  autoLogin: JSON.parse(sessionStorage.getItem("loginInfo")) || null
};

export const mutations = {
  SET_SESSION_INFO(state, sessionInfo) {
    state.sessionInfo = sessionInfo;
    sessionStorage.setItem("sessionInfo", JSON.stringify(state.sessionInfo))
  },
  SET_AUTO_LOGIN(state, loginInfo) {
    state.autoLogin = loginInfo;
    sessionStorage.setItem("loginInfo", JSON.stringify(state.autoLogin))
  },
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
    localStorage.setItem("currentUser", state.currentUser);
  },
  SET_AUTH_RESPONSE(state, authResponse) {
    state.authMessage = authResponse.data && authResponse.data.message || authResponse.message;
    state.errors = authResponse.data && authResponse.data.errors;
    state.status = authResponse.status;
    console.log(state.status, authResponse)
  },
  SET_USER_RESPONSE(state, userResponse) {
    state.userProfile = userResponse.data;
    state.errors = userResponse.data && userResponse.data.errors;
    state.status = userResponse.status;
    localStorage.setItem("userProfile", state.userProfile);
  },
  CLEAR_CURRENT_USER(state) {
    state.currentUser = null;
  },
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
};

export const actions = {
  // get session token
  async commitSession({ commit }) {
    try {
      commit("TOGGLE_LOADING");
      const sessionInfo = await userService.getSession();
      commit("TOGGLE_LOADING");
      // dispatch("fetchUserProfile")
      commit("SET_SESSION_INFO", sessionInfo);
    } catch (error) { 
      return error;
    }
  },

  async loginUser({ commit, dispatch }, user) {
    try {
      commit("TOGGLE_LOADING");
      const authMessage = await userService.login(user);
      dispatch("fetchUserProfile");
      commit("TOGGLE_LOADING");
      commit("SET_AUTH_RESPONSE", authMessage);
    } catch (error) {
      return error;
    }
  },

  async register({ commit }, user) {
    console.lo
    try {
      commit("TOGGLE_LOADING");
      const authResponse = await userService.register(user);
      commit("TOGGLE_LOADING");
      commit("SET_AUTO_LOGIN", user);
      commit("SET_AUTH_RESPONSE", authResponse);
    } catch (error) {
      return error;
    }
  },

  async getUserProfile({ commit }) {
    try {
      commit("TOGGLE_LOADING");
      const userResponse = await userService.getProfile();
      commit("TOGGLE_LOADING");
      commit("SET_USER_RESPONSE", userResponse);
    } catch (error) {
      return error;
    }
  },

  async sendOTP({ commit }, token) {
    try {
      commit("TOGGLE_LOADING");
      const authMessage = await userService.sendOTP(token);
      commit("TOGGLE_LOADING");
      commit("SET_AUTH_RESPONSE", authMessage);
    } catch (error) {
      return error;
    }
  },

  async verifyOTP({ commit }, otp) {
    try {
      commit("TOGGLE_LOADING");
      console.log("hit2")
      const authMessage = await userService.verifyOTP(otp);
      commit("TOGGLE_LOADING");
      commit("SET_AUTH_RESPONSE", authMessage);
    } catch (error) {
      return error;
    }
  },

  async logoutUser({ commit }) {
    try {
      await userService.logout(); // Call your logout API here if you have one
      sessionStorage.clear();
      commit("CLEAR_CURRENT_USER");
    } catch (error) {
      return error;
    }
  },
};

export const getters = {
  sessionInfo: (state) => state.sessionInfo,
  currentUser: (state) => state.currentUser,
  authMessage: (state) => state.authMessage,
};

