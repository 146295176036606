const apiUrl = process.env.API_URL || "http://localhost:8000/v1";

const headers = "";

import axios from "axios";

export const orderService = {
  getProducts,
  // createWarehouse,
};

async function getProducts() {
  try {
    const products = await axios.get(`${apiUrl}/pos/get-products`, {
      withCredentials: true,
      headers,
    });
    return products.data;
  } catch (error) {
    return error;
  }
}


// async function createWarehouse(warehouse) {
//   const token = warehouse && warehouse._token;
//   try {
//     const response = await axios.post(
//       `${apiUrl}/company/warehouse/addnew`,
//       warehouse,
//       {
//         withCredentials: true,
//         headers: {
//           "X-CSRF-TOKEN": token,
//         },
//       }
//     );
//     return response;
//   } catch (error) {
//     return error.response;
//   }
// }

