import { handleResponse } from "./response.service";

const apiUrl = process.env.API_URL || "http://localhost:8000/v1";

const headers = "";

import axios from "axios";

export const productService = {
  createProduct,
  getAllProducts,
  editCompany,
  getWarehouse,
  getWarehouses,
  editWarehouse,
};

async function createProduct(product) {
  const token = product && product._token;
  try {
    const prodResponse = await axios.post(
      `${apiUrl}/product/addnew`,
      product,
      {
        withCredentials: true,
        headers: {
          "X-CSRF-TOKEN": token,
        },
      }
    );
    return prodResponse;
  } catch (error) {
    return error.response;
  }
}



async function getAllProducts() {
  try {
    const prodResponse = await axios.get(`${apiUrl}/product/getall`, {
      withCredentials: true
    });
    return prodResponse.data;
  } catch (error) {
    return error;
  }
}

async function editCompany(company) {
  try {
    const response = await axios.post(
      `${apiUrl}/company/edit-company`,
      company,
      {
        withCredentials: true,
        headers,
      }
    );
    const companyData = handleResponse(response);
    return companyData.data;
  } catch (error) {
    return error;
  }
}




async function editWarehouse(warehouse) {
    const id = warehouse && warehouse.xid
    const token = warehouse && warehouse._token
  try {
    const compResponse = await axios.post(`${apiUrl}/company/warehouse/update/${id}`, warehouse, {
        withCredentials: true,
        headers: {
            "X-CSRF-TOKEN": token,
          },
      }
    );
    return compResponse;
  } catch (error) {
    return error;
  }
}

async function getWarehouses(token) {
  try {
    const warehouses = await axios.get(`${apiUrl}/company/warehouses`, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    return warehouses.data;
  } catch (error) {
    return error;
  }
}

async function getWarehouse(warehouse) {
  const id = (warehouse && warehouse.id) || "";
  try {
    const response = await axios.get(`${apiUrl}/company/warehouse/${id}`, {
      withCredentials: true,
      headers,
    });
    const warehouse = handleResponse(response);
    return warehouse.data;
  } catch (error) {
    return error;
  }
}
