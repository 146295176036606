const apiUrl = process.env.VUE_APP_API_URL || null;
// const apiOrigin = process.env.VUE_APP_API_ORIGIN || "http://localhost:8000/v1";

import axios from "axios";

export const userService = {
  login,
  register,
  getSession,
  getProfile,
  sendOTP,
  verifyOTP
};

async function getSession() {
  try {
    const session = await axios.get(`${apiUrl}/user/index`, {
      withCredentials: true,
    });
    return session.data;
  } catch (error) {
    return error;
  }
}

async function register(user) {
  const token = user && user._token;
  try {
    const authResponse = await axios.post(`${apiUrl}/user/signup`, user, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    console.log(authResponse);
    return authResponse;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

async function login(user) {
  const token = user && user._token;
  try {
    const authMesssage = await axios.post(`${apiUrl}/user/login`, user, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    console.log(authMesssage);
    return authMesssage;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
}

async function sendOTP(phone) {
  const token = phone && phone._token;
  try {
    const authMesssage = await axios.post(`${apiUrl}/user/phone/verify`, phone, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    console.log(authMesssage);
    return authMesssage;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
}

async function verifyOTP(otp) {
  const token = otp && otp._token;
  console.log(otp,token)
  try {
    const authMesssage = await axios.post(`${apiUrl}/user/email/verify/otp`, otp, {
      withCredentials: true,
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    console.log(authMesssage);
    return authMesssage;
  } catch (error) {
    console.error(error.response);
    return error.response;
  }
}


// async function login(user) {
//   let session = JSON.parse(sessionStorage.getItem("sessionInfo"));
//   const token = session ? session.O95HL3W : null;

//   console.log(token);

//   const headers = {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": apiUrl,
//     "X-XSRF-TOKEN": token,
//     "XSRF-TOKEN": token,
//   };
//   try {
//     const response = await axios.post(`${apiUrl}/user/login`, user, {
//       withCredentials: true,
//       headers,
//     });
//     const userData = handleResponse(response);
//     return userData.data;
//   } catch (error) {
//     return error;
//   }
// }

async function getProfile() {
  try {
    const userProfile = await axios.get(`${apiUrl}/user/get-profile`, {
      withCredentials: true,
    });
    return userProfile;
  } catch (error) {
    return error;
  }
}


